import { Controller } from "react-hook-form";

import { FieldError, Label } from "@/components/field";
import {
  Select,
  SelectButton,
  SelectListBox,
  SelectListItem,
  SelectPopover,
} from "@/components/select";

import { useCreateVideoRequestFormContext } from "../useCreateVideoRequestForm";

const DESIGN_COMPLEXITY_OPTIONS = [
  {
    id: 1,
    name: "Basic",
  },
  {
    id: 2,
    name: "Average",
  },
  {
    id: 3,
    name: "Complex",
  },
];

export function ComplexitySelect() {
  const methods = useCreateVideoRequestFormContext();

  return (
    <Controller
      control={methods.control}
      name="designComplexity"
      render={({
        field: { name, onBlur, onChange, value },
        fieldState: { error, invalid },
      }) => (
        <Select
          className="flex-1"
          isInvalid={invalid}
          name={name}
          onBlur={onBlur}
          onSelectionChange={onChange}
          placeholder="Select design complexity"
          selectedKey={value}
        >
          <Label requiredHint>Design complexity</Label>
          <SelectButton />
          <SelectPopover>
            <SelectListBox>
              {DESIGN_COMPLEXITY_OPTIONS.map(({ id, name }) => (
                <SelectListItem id={id} key={id}>
                  {name}
                </SelectListItem>
              ))}
            </SelectListBox>
          </SelectPopover>
          <FieldError>{error?.message}</FieldError>
        </Select>
      )}
    />
  );
}
