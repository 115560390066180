import { useMutation } from "@tanstack/react-query";
import { useSearch } from "@tanstack/react-router";
import { useEffect } from "react";

import { toast } from "@/components/toast/toast-queue";
import { useGetCurrentProjectId } from "@/hooks/useGetCurrentProjectId";
import { useUser } from "@/hooks/useUser";
import apiClient from "@/lib/api/client";

export function useCreateDraft() {
  const { data: userData } = useUser();
  const projectId = useGetCurrentProjectId();
  const { ["duplicate_from"]: duplicateFrom, ["request-type"]: requestType } =
    useSearch({
      from: "/requests/_layout/create",
    });

  const createDraft = useMutation({
    mutationFn: (projectId: number) =>
      apiClient
        .url("/requests")
        .post({
          projectId,
          requestType,
        })
        .json(),
    mutationKey: [
      "draftId",
      { projectId: projectId, userId: userData?.user.id },
    ],
    onError: () => {
      toast.add(
        {
          description: "Please try again later",
          title: "Error creating draft",
          type: "error",
        },
        {
          timeout: 5000,
        },
      );
    },
  });

  useEffect(() => {
    if (projectId && !duplicateFrom) {
      createDraft.mutate(projectId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return createDraft;
}
