import type { WretchResponse } from "wretch";

import apiClient from "@/lib/api/client";

import type { DetailedFormData } from "../schemas";

export async function createOutputEnhancement(
  data: DetailedFormData,
  abortController: AbortController,
  brandId?: number,
): Promise<WretchResponse> {
  return apiClient
    .url("/brief-creator/output-enhancement")
    .signal(abortController)
    .query({ detailed: true, stream: true, type: "video" })
    .post({ brandId, prompt: data })
    .res();
}
