import type { UseFormReturn, UseFormSetError } from "react-hook-form";

import { useMutation } from "@tanstack/react-query";
import { nanoid } from "nanoid";
import { useState } from "react";

import { useCapturePosthogEvent } from "@/hooks/posthog/useCapturePosthogEvent";
import apiClient from "@/lib/api/client";

import type {
  CreateGraphicRequestFormData,
  FileAsset,
  LinkAsset,
} from "../../useCreateGraphicRequestForm";
import type { LinkFormValues } from "../useLinkForm";

import { useCreateGraphicRequestFormContext } from "../../useCreateGraphicRequestForm";
import {
  hasDuplicateFileName,
  MAX_FILE_SIZE,
  MAX_FILES,
} from "../utils/uploadUtils";
import { useFileDelete } from "./useFileDelete";
import { useLinkManagement } from "./useLinkManagement";

type AssetField = "assets" | "inspirations";
type Asset = FileAsset | LinkAsset;

interface UploadUrlFields {
  attachmentId: number;
  fields: Record<string, string>;
  presignedUrl: string;
  url: string;
}

interface UseAssetsSectionReturn {
  assets: Asset[];
  error: null | string;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFileDelete: (awsKey: string) => void;
  handleLinkAdd: (url: { link: string }) => void;
  handleLinkDelete: (linkId: string) => void;
}

function useFileUpload(
  methods: UseFormReturn<CreateGraphicRequestFormData>,
  field: AssetField,
) {
  const capture = useCapturePosthogEvent();

  return useMutation({
    mutationFn: async ({
      field,
      newFile,
    }: {
      field: AssetField;
      newFile: FileAsset;
    }) => {
      const uploadUrlFields = await apiClient
        .query({
          fileName: newFile.file.name,
          size: newFile.file.size,
          type: field === "assets" ? "asset" : "example",
        })
        .get("/attachments/upload-url")
        .json<UploadUrlFields>();

      const formData = new FormData();

      for (const [key, value] of Object.entries(uploadUrlFields.fields)) {
        formData.append(key, value);
      }
      formData.append("file", newFile.file);

      const uploadResponse = await fetch(uploadUrlFields.presignedUrl, {
        body: formData,
        method: "POST",
      });

      if (!uploadResponse.ok) {
        throw new Error(`Upload failed with status ${uploadResponse.status}`);
      }

      return uploadUrlFields;
    },
    onError: (_error, variables) => {
      methods.setValue(
        field,
        methods
          .getValues(field)
          .map((asset) =>
            asset.id === variables.newFile.id
              ? { ...asset, status: "error" as const }
              : asset,
          ),
      );
    },
    onSuccess: (uploadUrlFields, variables) => {
      capture("brief_asset_upload", {
        file_type: variables.newFile.file.type,
        form_version: methods.getValues("briefCreatorType"),
      });

      methods.setValue(
        field,
        methods.getValues(field).map((asset) =>
          asset.id === variables.newFile.id
            ? {
                ...asset,
                attachmentId: uploadUrlFields.attachmentId,
                awsKey: uploadUrlFields.fields.key as string,
                status: "success" as const,
              }
            : asset,
        ),
      );
    },
  });
}

/**
 * Hook to manage assets section including file uploads and link management
 * @param field - The form field to manage ('assets' or 'inspirations')
 * @param setLinkFormError - Function to set errors in the link form
 */
export function useAssetsSection(
  field: AssetField,
  assets: Asset[],
  setLinkFormError: UseFormSetError<LinkFormValues>,
): UseAssetsSectionReturn {
  const methods = useCreateGraphicRequestFormContext();
  const [error, setError] = useState<null | string>(null);

  const { mutate: uploadAssetFile } = useFileUpload(methods, field);
  const { handleLinkAdd, handleLinkDelete } = useLinkManagement(
    field,
    assets,
    methods,
    setError,
    setLinkFormError,
  );
  const { handleFileDelete } = useFileDelete(field, assets, setError, methods);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setError(null);

    const files = event.target.files;

    if (!files) return;

    const uploadedFiles = [...files];

    if (uploadedFiles.length + assets.length > MAX_FILES) {
      setError(`You can only upload a maximum of ${MAX_FILES} files.`);

      return;
    }

    const newFiles: FileAsset[] = uploadedFiles.map((file) => ({
      attachmentId: null,
      awsKey: null,
      file,
      id: nanoid(),
      status: "uploading" as const,
    }));

    methods.setValue(field, [...newFiles, ...assets]);

    // Validate and upload each file
    for (const file of newFiles) {
      if (hasDuplicateFileName(file.file.name, assets)) {
        setError(`Cannot upload duplicate file: ${file.file.name}`);
        updateFileStatus(file.id, "error");
        continue;
      }

      if (file.file.size > MAX_FILE_SIZE) {
        setError(`File ${file.file.name} exceeds the maximum size of 200MB.`);
        updateFileStatus(file.id, "error");
        continue;
      }

      uploadAssetFile({ field, newFile: file });
    }
  };

  // Helper function to update file status
  const updateFileStatus = (fileId: string, status: "error" | "success") => {
    methods.setValue(
      field,
      methods
        .getValues(field)
        .map((asset) => (asset.id === fileId ? { ...asset, status } : asset)),
    );
  };

  return {
    assets,
    error,
    handleFileChange,
    handleFileDelete,
    handleLinkAdd,
    handleLinkDelete,
  };
}
