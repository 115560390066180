import { usePostHog } from "posthog-js/react";

import { useUser } from "@/hooks/useUser";

export const formTypeMap = {
  detailed: "magical_brief_form",
  single: "legacy",
} as const;

export function useCapturePosthogEvent() {
  const posthog = usePostHog();
  const user = useUser();

  return (
    eventName: string,
    properties?: Record<string, boolean | number | string>,
  ) => {
    posthog?.capture(eventName, {
      user: `user_${user.data?.user.id}`, // pattern requested by business team
      ...properties,
    });
  };
}
