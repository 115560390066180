import type {
  RadioGroupProps as RACRadioGroupProps,
  RadioProps as RACRadioProps,
  RadioRenderProps,
} from "react-aria-components";

import React from "react";
import {
  Radio as RACRadio,
  RadioGroup as RACRadioGroup,
} from "react-aria-components";
import { twMerge } from "tailwind-merge";

import { DescriptionContext } from "../field";
import { composeTailwindRenderProps, focusOutlineStyle } from "../utils";

export function RadioGroup({ ...props }: RACRadioGroupProps) {
  return <RACRadioGroup {...props} className={props.className} />;
}

export function Radios({ className, ...props }: JSX.IntrinsicElements["div"]) {
  return (
    <div
      className={twMerge(
        "flex flex-col group-aria-[orientation=horizontal]:flex-row group-aria-[orientation=horizontal]:flex-wrap",
        // When any radio item has description, apply all `font-medium` to all radio item labels
        "[&_label]:has-[[data-ui=description]]:font-medium",
        className,
      )}
      data-ui="box"
      {...props}
    />
  );
}

export interface RadioProps extends RACRadioProps {
  labelPosition?: "left" | "right";
  render?: never;
}

export interface CustomRenderRadioProps
  extends Omit<RACRadioProps, "children"> {
  children?: never;
  render: (props: RadioRenderProps) => React.ReactNode;
}

export function Radio({
  className,
  ...props
}: CustomRenderRadioProps | RadioProps) {
  const descriptionContext = React.useContext(DescriptionContext);

  if (props.render) {
    return (
      <RACRadio
        {...props}
        aria-describedby={descriptionContext?.["aria-describedby"]}
        className={composeTailwindRenderProps(className, [
          "text-base/6 sm:text-sm/6",
          "disabled:opacity-50",
        ])}
      >
        {props.render}
      </RACRadio>
    );
  }

  const { labelPosition = "right", ...restProps } = props;

  return (
    <RACRadio
      {...restProps}
      aria-describedby={descriptionContext?.["aria-describedby"]}
      className={composeTailwindRenderProps(className, [
        "group flex items-center gap-x-md",
        "group-aria-[orientation=horizontal]:text-nowrap",
        "data-[position=left]:flex-row-reverse",
        "data-[position=left]:justify-between",
      ])}
      data-position={labelPosition}
    >
      {(renderProps) => (
        <>
          <div
            className={twMerge(
              "grid size-[16px] shrink-0 place-content-center rounded-full border",
              "border-border-primary",
              renderProps.isInvalid && "border-destructive",
              renderProps.isSelected && [
                "border-bg-brand-solid bg-bg-brand-solid",
                "[&>div]:size-1.5 [&>div]:bg-bg-primary",
              ],
              renderProps.isFocusVisible && focusOutlineStyle,
              renderProps.isSelected &&
                renderProps.isDisabled &&
                "border-border-disabled bg-bg-disabled-subtle [&>div]:bg-fg-disabled-subtle",
            )}
            slot="radio"
          >
            <div className="rounded-full"></div>
          </div>

          {typeof props.children === "function"
            ? props.children(renderProps)
            : props.children}
        </>
      )}
    </RACRadio>
  );
}
