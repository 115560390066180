import { createFileRoute, useSearch } from "@tanstack/react-router";
import { z } from "zod";

import { CreateGraphicRequestForm } from "@/features/request/graphic";
import { CreateVideoRequestForm } from "@/features/request/video";

const requestCreateSchema = z.object({
  ["duplicate_count"]: z.number().optional(),
  ["duplicate_from"]: z.number().optional(),
  ["include_attachments"]: z.number().optional(),
  ["include_collaborators"]: z.number().optional(),
  ["project-id"]: z.number().optional(),
  ["request_id"]: z.number().optional(),
  ["request-type"]: z
    .enum(["video", "graphic"], { message: "Invalid request type" })
    .default("graphic"),
});

export const Route = createFileRoute("/requests/_layout/create")({
  component: Component,
  validateSearch: requestCreateSchema,
});

function Component() {
  const { ["request-type"]: requestType } = useSearch({
    from: "/requests/_layout/create",
  });

  return (
    <>
      <main className="min-h-screen bg-bg-tertiary py-4xl">
        {requestType === "video" && <CreateVideoRequestForm />}
        {requestType === "graphic" && <CreateGraphicRequestForm />}
      </main>
    </>
  );
}
