import type { GetMeUserResponseZod } from "@nlc/shared-types";
import type * as z from "zod";

import { useQuery } from "@tanstack/react-query";

import apiClient from "@/lib/api/client";

export function useUser() {
  const query = useQuery<z.infer<typeof GetMeUserResponseZod>>({
    queryFn: () => apiClient.url("/users/me").get().json(),
    queryKey: ["user"],
  });

  const currentProject = query.data?.projects.find(
    (project) => project.id === query.data.currentProjectId,
  );

  return {
    ...query,
    currentProject,
  };
}
