import type { UseFormReturn } from "react-hook-form";

import { useMutation } from "@tanstack/react-query";
import * as z from "zod";

import { toast } from "@/components/toast/toast-queue";
import { useIsMBCSubmitted } from "@/features/request/shared/useIsMBCSubmitted";
import { countNonEmptyFields } from "@/hooks/posthog/countNonEmptyFields";
import {
  formTypeMap,
  useCapturePosthogEvent,
} from "@/hooks/posthog/useCapturePosthogEvent";
import { useIsRequestFormDirty } from "@/hooks/useIsRequestFormDirty";
import { useUser } from "@/hooks/useUser";
import authenticatedApi from "@/lib/api/client";

import type { CreateGraphicRequestFormData } from "../components/useCreateGraphicRequestForm";

const submitRequestBodyZod = z.object({
  attachment_ids: z.array(z.number()).optional(),
  brand_id: z.number().optional(),
  brief_text: z.coerce.string().optional(), // for magical brief
  ca_type: z.string().optional(), // creative direction
  collaborator_ids: z.array(z.number()).optional(),
  complexity_id: z.number().optional(),
  description: z.string(), // for single brief
  design_type_id: z.number(),
  is_draft: z.number(),
  length_or_platform: z.string().optional(),
  music_genre: z.string().optional(),
  name: z.string(),
  preferred_designer_ids: z.array(z.number()).optional(),
  preferred_file_type: z.string().optional(),
  request_type: z.string(),
  sizes_needed: z.string(),
  style_of_video: z.string().optional(),
  text_for_design: z.string(), // for single brief
});

type UseSubmitMutationProps = {
  formMethods: UseFormReturn<CreateGraphicRequestFormData>;
  latestRequestId: { requestId: number };
};

export function useSubmitMutation({
  formMethods,
  latestRequestId,
}: UseSubmitMutationProps) {
  const { data: user } = useUser();
  const resetFormDirty = useIsRequestFormDirty((state) => state.reset);
  const setIsMBCSubmitted = useIsMBCSubmitted((state) => state.setIsSubmitted);
  const capture = useCapturePosthogEvent();

  return useMutation({
    mutationFn: async (formData: CreateGraphicRequestFormData) => {
      if (!latestRequestId) throw new Error("No request ID found");

      const commonFields = {
        attachment_ids: [
          ...formData.assets.map((asset) => asset.attachmentId),
          ...formData.inspirations.map(
            (inspiration) => inspiration.attachmentId,
          ),
        ],
        brand_id: formData.brand,
        ca_type: formData.creativeDirection,
        collaborator_ids: formData.collaborators.map(Number),
        complexity_id: formData.designComplexity,
        design_type_id: formData.designType,
        is_draft: 0,
        name: formData.requestName,
        preferred_designer_ids: formData.preferredDesigners.map(Number),
        preferred_file_type: formData.fileTypes,
        request_type: "graphic" as const,
        sizes_needed: formData.sizes,
      };

      const briefTypeFields =
        formData.briefCreatorType === "detailed"
          ? {
              brief_text: formData.briefCreatorDetailed.finalBriefHTML,
              description: formData.briefCreatorSingle.requestDescriptionHTML,
              text_for_design: formData.briefCreatorDetailed.keyMessageAndCta,
            }
          : {
              brief_text: undefined,
              description: formData.briefCreatorSingle.requestDescriptionHTML,
              text_for_design: formData.briefCreatorSingle.textsForDesignHTML,
            };

      const parsed = submitRequestBodyZod.parse({
        ...commonFields,
        ...briefTypeFields,
      });

      return authenticatedApi
        .url(`/requests/${latestRequestId.requestId}/submit`)
        .post(parsed)
        .json<{ message: string; request_data: unknown }>();
    },
    onError: (error) => {
      console.error("Failed to submit request:", error);

      const { briefCreatorType: _briefCreatorType, ...formValues } =
        formMethods.getValues();

      capture("submit_request", {
        ai_usage_flag: formMethods.getValues("briefCreatorType") === "detailed",
        brief_type: "graphic",
        form_version: formTypeMap[formMethods.getValues("briefCreatorType")],
        input_fields_completed: countNonEmptyFields(formValues),
        submission_success: false,
      });

      if (error.message.includes("Request name already exists")) {
        formMethods.setError("requestName", {
          message: "Request name already exists",
        });
        formMethods.setFocus("requestName");
      }

      toast.add(
        {
          description: error.message,
          title: "Error submitting request",
          type: "error",
        },
        {
          timeout: 5000,
        },
      );
    },
    onMutate: () => {
      formMethods.reset(formMethods.getValues());
      resetFormDirty();
    },
    onSuccess: () => {
      const { briefCreatorType: _briefCreatorType, ...formValues } =
        formMethods.getValues();

      capture("submit_request", {
        ai_usage_flag: formMethods.getValues("briefCreatorType") === "detailed",
        brief_type: "graphic",
        form_version: formTypeMap[formMethods.getValues("briefCreatorType")],
        input_fields_completed: countNonEmptyFields(formValues),
        submission_success: true,
      });

      setIsMBCSubmitted(false); // reset the MBC submission flag

      window.location.href =
        user &&
        ["admin", "collaborator", "owner"].includes(user.user.roleName || "")
          ? "/requests?queued=1"
          : "/requests";
    },
  });
}
