export function FullPageLoader() {
  return (
    <div className="fixed inset-0 z-50 flex h-screen w-screen flex-col items-center justify-center gap-xl bg-bg-tertiary/70">
      <svg
        className="animate-spin"
        fill="none"
        height="56"
        viewBox="0 0 56 56"
        width="56"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M53 28C53 31.283 52.3534 34.5339 51.097 37.5671C49.8406 40.6002 47.9991 43.3562 45.6777 45.6777C43.3562 47.9991 40.6002 49.8406 37.5671 51.097C34.5339 52.3534 31.283 53 28 53C24.7169 53 21.466 52.3534 18.4329 51.097C15.3998 49.8406 12.6438 47.9991 10.3223 45.6777C8.00086 43.3562 6.15938 40.6002 4.90301 37.5671C3.64664 34.5339 3 31.283 3 28C3 24.7169 3.64665 21.466 4.90302 18.4329C6.15938 15.3998 8.00087 12.6438 10.3223 10.3223C12.6438 8.00086 15.3998 6.15937 18.4329 4.90301C21.4661 3.64664 24.717 3 28 3C31.2831 3 34.534 3.64665 37.5671 4.90302C40.6002 6.15939 43.3562 8.00087 45.6777 10.3223C47.9991 12.6438 49.8406 15.3998 51.097 18.4329C52.3534 21.4661 53 24.717 53 28L53 28Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="6"
        />
        <path
          d="M10.3223 10.3223C13.1121 7.53261 16.5224 5.44274 20.2746 4.22359C24.0267 3.00443 28.0142 2.69062 31.9109 3.30779C35.8076 3.92497 39.5029 5.45561 42.6946 7.77458C45.8864 10.0935 48.4841 13.135 50.2752 16.6502C52.0663 20.1655 53 24.0547 53 28C53 31.9453 52.0663 35.8345 50.2752 39.3498C48.484 42.865 45.8864 45.9065 42.6946 48.2254C39.5028 50.5444 35.8075 52.075 31.9109 52.6922"
          stroke="#6938EF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="6"
        />
      </svg>

      <p className="text-lg font-medium text-text-secondary">Loading...</p>
    </div>
  );
}
