import type { UseFormReturn } from "react-hook-form";

import apiClient from "@/lib/api/client";

import type {
  CreateGraphicRequestFormData,
  FileAsset,
  LinkAsset,
} from "../../useCreateGraphicRequestForm";

import { isFileAsset } from "../utils/uploadUtils";

type AssetField = "assets" | "inspirations";

export function useFileDelete(
  field: AssetField,
  assets: (FileAsset | LinkAsset)[],
  setError: (error: null | string) => void,
  methods: UseFormReturn<CreateGraphicRequestFormData>,
): {
  handleFileDelete: (fileId: string) => Promise<void>;
} {
  const handleFileDelete = async (fileId: string) => {
    setError(null);

    const fileAsset = assets.find(
      (asset): asset is FileAsset =>
        "attachmentId" in asset && asset.id === fileId,
    );

    if (!fileAsset || !fileAsset.id) {
      console.error(`File not found`);

      return;
    }

    try {
      if (fileAsset.awsKey) {
        await apiClient
          .delete(
            `/attachments/delete-file/${encodeURIComponent(fileAsset.awsKey)}`,
          )
          .json();
      }

      const filteredAssets = assets.filter(
        (asset) => !isFileAsset(asset) || asset.id !== fileId,
      );

      methods.setValue(field, filteredAssets);
    } catch (error) {
      console.error(`Error deleting file ${fileId}:`, error);

      const updatedFilesWithError = assets.map((asset) =>
        isFileAsset(asset) && asset.id === fileId
          ? { ...asset, status: "error" as const }
          : asset,
      );

      methods.setValue(field, updatedFilesWithError);
      setError(
        `Failed to delete file ${fileAsset.file.name}. Please try again.`,
      );
    }
  };

  return { handleFileDelete };
}
