import type { DuplicateRequestResponseZod } from "@nlc/shared-types";
import type { UseFormReset } from "react-hook-form";
import type * as z from "zod";

import { useMutation } from "@tanstack/react-query";
import { useSearch } from "@tanstack/react-router";
import { useNavigate } from "@tanstack/react-router";
import { convert } from "html-to-text";
import { useEffect } from "react";

import { toast } from "@/components/toast/toast-queue";
import { useUser } from "@/hooks/useUser";
import apiClient from "@/lib/api/client";

import type { CreateVideoRequestFormData } from "../components/useCreateVideoRequestForm";

type DuplicateRequestResponse = z.infer<typeof DuplicateRequestResponseZod>;
type Attachment = NonNullable<DuplicateRequestResponse["attachments"]>[number];
type FileAttachment = {
  attachmentId: number;
  awsKey: null;
  file: File;
  id: string;
  status: "success";
};
type LinkAttachment = { attachmentId: number; id: string; url: string };

interface UseDuplicateRequestProps {
  reset: UseFormReset<CreateVideoRequestFormData>;
}

function createFileAttachment(att: Attachment): FileAttachment {
  return {
    attachmentId: att.id,
    awsKey: null,
    file: new File([], att.name),
    id: att.id.toString(),
    status: "success",
  };
}

function createLinkAttachment(att: Attachment): LinkAttachment {
  return {
    attachmentId: att.id,
    id: att.id.toString(),
    url: att.url,
  };
}

function mapAttachmentsByCategory(
  attachments: DuplicateRequestResponse["attachments"],
  category: string,
) {
  if (!attachments) return [];

  return attachments
    .filter((att) => att.category === category)
    .map((att) =>
      att.type === "link"
        ? createLinkAttachment(att)
        : createFileAttachment(att),
    );
}

function mapDuplicateResponseToFormData(
  data: DuplicateRequestResponse,
): CreateVideoRequestFormData {
  const briefCreatorType = data.brief_text ? "detailed" : "single";

  return {
    assets: mapAttachmentsByCategory(data.attachments, "asset"),
    brand: data.brand_id || undefined,
    briefCreatorDetailed: {
      additionalGuidelines: "",
      brandGuidelines: "",
      creativeGoal: "",
      finalBriefHTML: data.brief_text || "",
      finalBriefText: data.brief_text || "",
      scope: "",
      script: convert(data.text_for_design || ""),
    },
    briefCreatorSingle: {
      requestDescriptionHTML: data.description || "",
      requestDescriptionText: data.description || "",
      textsForDesignHTML: data.text_for_design || "",
      textsForDesignText: data.text_for_design || "",
    },
    briefCreatorType,
    collaborators: data.collaborator_ids?.map(String) || [],
    creativeDirection: data.creative_direction || undefined,
    designComplexity: data.complexity_id || undefined,
    fileTypes: data.preferred_file_type || "",
    inspirations: mapAttachmentsByCategory(data.attachments, "example"),
    lengthOrPlatform: data.length_or_platform || "",
    musicGenre: data.music_genre || "",
    preferredDesigners: data.preferred_designer_ids?.map(String) || [],
    requestName: data.name || "",
    sizes: data.sizes_needed || "",
    style: data.style_of_video || "",
    videoType: data.design_type_id || 0,
  };
}

export function useDuplicateRequest({ reset }: UseDuplicateRequestProps) {
  const navigate = useNavigate({ from: "/requests/create" });
  const { data: user } = useUser();
  const search = useSearch({ from: "/requests/_layout/create" });

  const {
    duplicate_count: duplicateCount,
    duplicate_from: duplicateFrom,
    include_attachments: includeAttachments,
    include_collaborators: includeCollaborators,
  } = search;

  function handleSuccess(data: DuplicateRequestResponse) {
    // Handle multiple duplicates
    if (duplicateCount && duplicateCount > 1) {
      const isPrivilegedUser =
        user &&
        ["admin", "collaborator", "owner"].includes(user.user.roleName || "");

      window.location.href = isPrivilegedUser
        ? "/requests?queued=1"
        : "/requests";

      return;
    }

    // Handle single duplicate
    navigate({
      search: (prev) => ({ ...prev, request_id: data.id }),
    });

    const mappedRequest = mapDuplicateResponseToFormData(data);

    reset(mappedRequest, {
      keepDefaultValues: true,
      keepDirty: false,
      keepErrors: false,
      keepIsSubmitted: false,
      keepIsValid: false,
      keepSubmitCount: false,
      keepTouched: false,
    });
  }

  const { isPending, isSuccess, mutate } = useMutation({
    mutationFn: () =>
      apiClient
        .url(`/requests/${duplicateFrom}/duplicate`)
        .post({
          includeAttachments: includeAttachments === 1,
          includeCollaborators: includeCollaborators === 1,
          numberOfDuplicates: duplicateCount,
        })
        .json<DuplicateRequestResponse>(),
    mutationKey: ["duplicate-request", duplicateFrom],
    onError: (error) => {
      console.error(error);
      toast.add({
        description: error.message,
        title: "Error duplicating request",
        type: "error",
      });
    },
    onSuccess: handleSuccess,
    retry: 1,
  });

  useEffect(() => {
    if (duplicateFrom && !isSuccess && !isPending) {
      mutate();
    }
  }, [duplicateFrom, isSuccess, isPending, mutate]);

  return {
    isInDuplicateMode: duplicateFrom !== undefined,
    isPending,
  };
}
