import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

type MBCSubmittedState = {
  isSubmitted: boolean;
  setIsSubmitted: (isSubmitted: boolean) => void;
};

export const useIsMBCSubmitted = create<MBCSubmittedState>()(
  subscribeWithSelector((set) => ({
    isSubmitted: false,
    setIsSubmitted: (isSubmitted) => set({ isSubmitted }),
  })),
);
