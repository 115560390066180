import type { UseFormWatch } from "react-hook-form";

import { useEffect } from "react";
import { useRef } from "react";

import { useCapturePosthogEvent } from "./useCapturePosthogEvent";

type AnalyticsData = {
  modification_count: number;
  regeneration_count: number;
};

type FormValues = {
  briefCreatorDetailed: Record<string, unknown>;
};

export function useFormChangeTracking<T extends FormValues>(
  watch: UseFormWatch<T>,
) {
  const capture = useCapturePosthogEvent();
  const regenerationCount = useRef(0);
  const modificationCount = useRef(0);

  useEffect(() => {
    const subscription = watch((_value, { name }) => {
      if (name?.startsWith("briefCreatorDetailed.")) {
        modificationCount.current += 1;
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const trackEvent = (eventName: string) => {
    const data: AnalyticsData = {
      modification_count: modificationCount.current,
      regeneration_count: regenerationCount.current,
    };

    capture(eventName, data);
  };

  const incrementRegeneration = () => {
    regenerationCount.current += 1;
  };

  return {
    incrementRegeneration,
    trackEvent,
  };
}
