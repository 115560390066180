import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";

import { isValidURL } from "@/utils/isValidUrl";

import type { FileAsset, LinkAsset } from "../useCreateGraphicRequestForm";

const createLinkSchema = (existingAssets: (FileAsset | LinkAsset)[]) =>
  z.object({
    link: z
      .string()
      .max(1000, "Link is too long.")
      .refine((val) => !val || isValidURL(val), {
        message: "Link is invalid.",
      })
      .refine(
        (val) =>
          !val ||
          !existingAssets.some((asset) => "url" in asset && asset.url === val),
        {
          message: "This link has already been added.",
        },
      ),
  });

export type LinkFormValues = z.infer<ReturnType<typeof createLinkSchema>>;

export function useLinkForm(assets: (FileAsset | LinkAsset)[]) {
  const {
    clearErrors,
    control,
    formState,
    handleSubmit,
    reset,
    setError,
    trigger,
  } = useForm<LinkFormValues>({
    defaultValues: {
      link: "",
    },
    resolver: zodResolver(createLinkSchema(assets)),
  });

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ link: "" });
    }
  }, [formState, reset]);

  // Trigger validation when assets change to check for duplicates
  useEffect(() => {
    trigger("link");
  }, [assets, trigger]);

  return { clearErrors, control, formState, handleSubmit, setError };
}
