export function countNonEmptyFields(obj: Record<string, unknown>): number {
  let count = 0;

  // Helper function to check if a value is considered empty
  const isEmpty = (value: unknown): boolean => {
    if (value === undefined || value === null || value === "") {
      return true;
    }
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }
    if (typeof value === "object" && value !== null) {
      // For nested objects, check if all their values are empty
      return Object.values(value).every((v) => isEmpty(v));
    }

    return false;
  };

  // Helper function to recursively traverse the object
  const traverse = (obj: Record<string, unknown>) => {
    for (const key in obj) {
      const value = obj[key];

      if (
        typeof value === "object" &&
        value !== null &&
        !Array.isArray(value)
      ) {
        // For nested objects, traverse recursively
        traverse(value as Record<string, unknown>);
      } else {
        // For non-object values, check if they're non-empty
        if (!isEmpty(value)) {
          count++;
        }
      }
    }
  };

  traverse(obj);

  return count;
}
